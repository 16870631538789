<template>
  <Pill
  :text="difficultyDisplayName"
  :cssClasses="pillClass"
  >
  </Pill>
</template>

<script>
import Pill from '@/components/utils/pills/Pill'
import mapper from '@/services/mixins/mapper.mixins'

import { DIFFICULTIES } from '@/config/constants'

export default {
  name: 'DifficultyPill',
  components: { Pill },
  mixins: [mapper],
  props: {
    difficulty: { required: true }
  },
  computed: {
    difficultyDisplayName () { return this.getDifficultyDisplayName(this.difficulty) },
    pillClass () {
      switch (this.difficulty) {
        case DIFFICULTIES.VERY_LOW: {
          return { 'very-easy': true }
        }
        case DIFFICULTIES.LOW: {
          return { easy: true }
        }
        case DIFFICULTIES.MEDIUM: {
          return { medium: true }
        }
        case DIFFICULTIES.HARD: {
          return { hard: true }
        }
        case DIFFICULTIES.EXTREME: {
          return { extreme: true }
        }
        default: {
          return { secondary: true }
        }
      }
    }
  }

}
</script>
