<template>
  <span
  class="pill"
  :class="pillClass"
  >
    <slot>
      <span class="icon-wrapper">
        <b-icon :icon="icon" :variant="variant" v-if="icon"/>
        <span v-else>{{ number }}</span>
      </span>
      <span class="text">{{ text }}</span>
    </slot>
  </span>
</template>

<script>
export default {
  props: {
    text: { required: false, default: '' },
    icon: { type: String, required: false, default: '' },
    variant: { type: String, required: false, default: '' },
    number: { required: false, default: '' },
    cssClasses: { type: Object, required: false, default: () => {} }
  },
  computed: {
    pillClass () {
      return {
        ...this.cssClasses,
        'with-icon': this.icon || this.number
      }
    }
  }

}
</script>
