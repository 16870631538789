<template>
  <Pill
  :text="statusDisplayName"
  :cssClasses="pillClass"
  >
  </Pill>
</template>

<script>
import Pill from '@/components/utils/pills/Pill'
import mapper from '@/services/mixins/mapper.mixins'

import { PILL_STATUS } from '@/config/constants'

export default {
  name: 'status-pill',
  components: { Pill },
  mixins: [mapper],
  props: {
    status: { required: true }
  },
  computed: {
    statusDisplayName () { return this.getPillStatusDisplayName(this.status) },
    pillClass () {
      switch (this.status) {
        case PILL_STATUS.COMPLETE: {
          return { complete: true }
        }
        case PILL_STATUS.IN_PROGRESS: {
          return { 'in-progress': true }
        }
        default: {
          return { pending: true }
        }
      }
    }
  }

}
</script>
