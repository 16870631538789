<template>
<div>
  <b-table
    sort-icon-left
    :tbody-tr-class="rowClass"
    :items="items"
    :fields="fields"
    :per-page="perPage"
    :current-page="currentPage"
    :sort-by.sync="sortBy"
    hover
    show-empty
    responsive
    :busy="busy"
    table-class="spaced"
    thead-tr-class="table-head"
    @row-clicked="goToPill"
    class="bordered-bottom"
    >

  <!-- A custom formatted column -->
    <template #cell(name)="data">
      <span class="body-2">{{ data.value }}</span><br>
      <span class="description paragraph">{{ shortened(data.item.description) | striphtml }}</span>
    </template>

    <!-- A custom formatted column -->
    <template #cell(type)="data">
      <TypePill :type="data.value" />
    </template>

    <!-- A custom formatted column -->
    <template #cell(difficulty)="data">
      <DifficultyPill :difficulty="data.value" />
    </template>

        <!-- A custom formatted column -->
    <template #cell(duration)="data">
     {{ data.value }} {{ data.value == '1'? $t('GENERAL.minute') : $t('GENERAL.minutes') }}
    </template>

    <!-- A custom formatted column -->
    <template #cell(student_pill_completion.completion_status)="data">
      <StatusPill :status="data.value" />
    </template>

    <template v-slot:empty>
      <div class="text-center">
        {{ $t('PILLS.no_results') }}
      </div>
    </template>

    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner class="align-middle mr-2"></b-spinner>
        <strong>{{ $t('GENERAL.loading') }}...</strong>
      </div>
    </template>

  </b-table>

  <div class="flex align-center justify-content-end">
    <span class="mr-2">
      {{ $t('TABLE.items_total') }}: {{ items.length }}
    </span>
    |
    <span class="ml-2">
    {{ $t('TABLE.items_per_page') }}
    </span>
    <b-dropdown :text="perPage" variant="outline-primary" class="ml-2 mr-4">
      <b-dropdown-item :variant="perPage === '25' ? 'primary':'outline-primary'" @click="perPage = '25'">25</b-dropdown-item>
      <b-dropdown-item :variant="perPage === '50' ? 'primary':'outline-primary'" @click="perPage = '50'">50</b-dropdown-item>
      <b-dropdown-item :variant="perPage === '75' ? 'primary':'outline-primary'" @click="perPage = '75'">75</b-dropdown-item>
    </b-dropdown>

    <b-pagination
      v-if="perPage < items.length"
      v-model="currentPage"
      :total-rows="items.length"
      :per-page="perPage"
      first-number
      last-number
      :first-text="$t('TABLE.first')"
      :prev-text="$t('TABLE.previous')"
      :next-text="$t('TABLE.next')"
      :last-text="$t('TABLE.last')"
      class="mb-0"
    ></b-pagination>
  </div>

</div>

</template>

<script>
import DifficultyPill from '@/components/utils/pills/DifficultyPill'
import TypePill from '@/components/utils/pills/TypePill'
import StatusPill from '@/components/utils/pills/StatusPill'

import { PILL_STATUS } from '@/config/constants'

export default {
  name: 'pills-table',
  components: { DifficultyPill, TypePill, StatusPill },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    busy: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      currentPage: 1,
      perPage: '25',
      sortBy: 'name',
      fields: [
        { key: 'name', label: this.$t('PILLS.name'), sortable: true },
        { key: 'type', label: this.$t('PILLS.type'), sortable: true },
        { key: 'difficulty', label: this.$t('PILLS.difficulty'), sortable: true },
        { key: 'duration', label: this.$t('PILLS.duration'), sortable: true },
        { key: 'points', label: this.$t('PILLS.points'), sortable: true },
        { key: 'student_pill_completion.completion_status', label: this.$t('PILLS.status') }
      ]
    }
  },
  methods: {
    rowClass (item, type) {
      const cls = { clickable: true }
      if (!item || type !== 'row') return
      if (typeof item.student_pill_completion !== 'undefined') {
        if (item.student_pill_completion.completion_status === PILL_STATUS.PENDING) cls.pending = true
        if (item.student_pill_completion.completion_status === PILL_STATUS.IN_PROGRESS) cls.in_progress = true
        if (item.student_pill_completion.completion_status === PILL_STATUS.COMPLETE) cls.complete = true
      }

      return cls
    },
    shortened (str) {
      var clean = str.replace(/(<([^>]+)>)/gi, '')
      return clean && clean.length < 50 ? clean : clean.slice(0, 50).concat('...')
    },
    goToPill (pill) {
      this.$router.push({ name: 'pillDetail', params: { id: pill.id } })
    }
  }
}
</script>

<style lang="scss">
  .table td span.description {
    display: inline-block;
    color: $grey-medium;
    font-size: 0.8rem;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .table.bordered-bottom td {
    border-bottom: 1px solid $grey;
  }

  table.spaced {
    border-spacing: 0px 6px;
    border-collapse: separate;
  }
</style>
