<template>
  <Pill
  :text="typeDisplayName"
  variant="white"
  :cssClasses="pillClass"
  >
  </Pill>
</template>

<script>
import Pill from '@/components/utils/pills/Pill'
import mapper from '@/services/mixins/mapper.mixins'

import { PILL_TYPES } from '@/config/constants'

export default {
  name: 'type-pill',
  components: { Pill },
  mixins: [mapper],
  props: {
    type: { required: true }
  },
  computed: {
    typeDisplayName () { return this.getPillTypeDisplayName(this.type) },
    pillClass () {
      switch (this.type) {
        case PILL_TYPES.THEORY: {
          return { theory: true }
        }
        case PILL_TYPES.PRACTICE: {
          return { practice: true }
        }
        default: {
          return { secondary: true }
        }
      }
    }
  }
}
</script>
